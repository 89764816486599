:root {
  font-size: 16px;
  margin: 0;
  padding: 0;

  --bg-color: #fff;
  --txt-color: #0a040a;
  --link-color: #1253e8;
  --highlight-color: #ffb300;
  --logo-color: #14ff1f;
}

html,
body {
  background-color: var(--bg-color);
  color: var(--txt-color);
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 8rem;
  padding: 0;
  margin: 3rem auto;
  line-height: 1;
}
h1 span {
  padding: 0;
  margin: 0;
  line-height: 1;

  display: inline-block;
  transform-origin: center bottom;
  animation: fallRight 0.5s linear forwards 1s;
}

@keyframes fallRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateX(-70%) translateY(-19.2%) rotate(90deg);
  }
}

h2 {
  font-size: 3rem;
  margin: 3rem auto;
  padding: 0;
  width: 500px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.links {
  cursor: pointer;
  color: var(--link-color);
}

.contact-email {
  cursor: pointer;
  color: var(--link-color);
}
.copied {
  font-size: 1rem;
  padding: 8px 20px;
  border-radius: 10px;
  margin: 0;
  background-color: var(--highlight-color);
  color: var(--txt-color);
  opacity: 0;
  transition: opacity 0.7s;
  font-weight: normal;
}

.visibile {
  opacity: 1;
}

@media (max-width: 768px) {
  :root {
    font-size: 12px;
  }

  .App {
    width: 100%;
    margin: 10px;
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 2rem;
    margin: 3rem auto;
    padding: 0;
    width: 94%;
  }

  .App h2 span {
    font-size: 2rem;
    color: var(--link-color);
  }
}
